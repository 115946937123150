import { BellNotification } from 'src/components/icons/BellNotification'
import { ChatDots } from 'src/components/icons/ChatDots'
import { NegocizioPiuVicino } from 'src/components/icons/NegocioPiuVicino'

import './topbaralert.scss'

const TopBarAlert = () => {
  return (
    <>
      <div data-alert-content>
        <a
          className="alert__content alert__content--negozio"
          href="https://negozi.arcaplanet.it/"
        >
          <NegocizioPiuVicino />
          <span>Negozio più vicino</span>
        </a>
        <span className="alert__content alert__content--title">
          <span>
          Assistenza telefonica attiva solo in&nbsp;
            <span className="alert__span-color"><a href="/#comunicazione-chiusura-aziendale">date selezionate</a></span>
          </span>
        </span>
        <span className="alert__content alert__content--right">
          {/* Note that external links still use `a` tags. */}
          <a
            className="alert__content alert__content--assistenza"
            href="https://agrifarma--partial.sandbox.my.site.com/assistenza/s/"
          >
            <ChatDots />
            <span>Assistenza Clienti</span>
          </a>

          {/* Note that external links still use `a` tags. */}
          <a
            className="alert__content alert__content--status"
            href="https://secure.arcaplanet.it/account#/orders"
          >
            <BellNotification />
            <span className="alert__span--status">Status Ordini</span>
          </a>
        </span>
      </div>
    </>
  )
}

export default TopBarAlert
